<template>
  <v-container>
    <v-row no-gutters class="login-modal" justify="center">
      <v-row no-gutters class="mt-3">
        <v-col cols="10" />
        <v-col cols="2">
          <!-- Close icon -->
          <img
            style="z-index: 500; cursor: pointer"
            class="login-modal-background login-modal-background--close"
            src="@/assets/iconsChile/backgrounds/close.svg"
            @click="closeModal"
          />
        </v-col>
      </v-row>
      <div>
        <img
          class="login-modal-background login-modal-background--top"
          :src="getBackgroundImg(1)"
        />
        <img
          class="login-modal-background login-modal-background--bottom"
          :class="{ 'login-modal-background--top-right': pathTo === 'Favorites' || inSchoolCard }"
          :src="getBackgroundImg(2)"
        />
      </div>
      <div class="login-modal__div ma-5">
        <div v-t="textSelection()" />
        <div
          class="login-modal__btn_container pt-3"
          :class="{ 'login-modal__btn_container--left': !isGuest }"
        >
          <BaseButton v-if="isGuest" class="btn-continue" @click="signUp">
            {{ $t('explorer.login_modal.register') }}
          </BaseButton>
          <BaseButton class="btn-continue" @click="closeModal">
            {{ $t('explorer.login_modal.explore') }}
          </BaseButton>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseButton from '../base/BaseButton.vue';

export default {
  name: 'WarningModal',
  components: {
    BaseButton,
  },
  props: {
    pathTo: {
      type: String,
      default: null,
    },
    inSchoolCard: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      isGuest: 'authentication/isGuest',
    }),
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    signUp() {
      this.$emit('go-register');
    },
    textSelection() {
      if (this.inSchoolCard) {
        return 'explorer.login_modal.in_card_text';
      }
      if (this.isGuest && this.pathTo === 'Favorites') {
        return 'explorer.login_modal.favorites_text';
      }
      if (this.isGuest && this.pathTo === 'Simulate') {
        return 'explorer.login_modal.admission_text';
      }
      if (!this.isGuest && this.pathTo === 'Favorites') {
        return 'explorer.login_modal.favorites_logged_text';
      }
      if (!this.isGuest && this.pathTo === 'Simulate') {
        return 'explorer.login_modal.admission_logged_text';
      }
      return '';
    },
    getBackgroundImg(number) {
      let icon;
      if (this.inSchoolCard) {
        if (number === 1) {
          icon = 'list.svg';
        } else if (number === 2) {
          icon = 'list2.svg';
        }
      }
      if (this.pathTo === 'Favorites' && !this.inSchoolCard) {
        if (number === 1) {
          icon = 'list.svg';
        } else if (number === 2) {
          icon = 'list2.svg';
        }
      }
      if (this.pathTo === 'Simulate' && !this.inSchoolCard) {
        if (number === 1) {
          icon = 'simulate2.svg';
        } else if (number === 2) {
          icon = 'simulate.svg';
        }
      }
      // eslint-disable-next-line
      const svg = require('@/assets/iconsChile/backgrounds/' + icon);
      return svg;
    },
  },
};
</script>
