var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"campus-search-bar",class:{
    'campus-search-bar--mobile': _vm.mobile,
    'campus-search-bar--dashboard': _vm.inDashboard,
    'campus-search-bar--dashboard--mobile': _vm.inDashboard && _vm.mobile,
  }},[_c('div',{staticClass:"campus-search-bar__input campus-search-bar__input--center",class:{ 'campus-search-bar__input--mobile': _vm.mobile }},[_c('v-text-field',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
        handler: _vm.cleanResults,
      }),expression:"{\n        handler: cleanResults,\n      }"}],attrs:{"loading":_vm.loading,"clearable":"","hide-details":"","item-text":"campus_name","label":_vm.setTextFieldLabel(),"prepend-inner-icon":_vm.mobile ? '' : 'mdi-magnify',"solo":""},on:{"focus":function($event){return _vm.toggleSearchBar(true)}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('v-expand-transition',[(
          _vm.searchBarIsFocused && (_vm.suggestedCampuses.length > 0 || (_vm.searchTerm?.length > 0 && !_vm.loading)))?_c('v-list',{staticClass:"campus-search-bar__results",class:{
          'campus-search-bar__results--mobile': _vm.mobile,
          'campus-search-bar__results--dashboard': _vm.inDashboard,
          'campus-search-bar__results--dashboard--mobile': _vm.inDashboard && _vm.mobile,
        }},[(0 < _vm.searchTerm?.length && _vm.searchTerm?.length < 3)?_c('v-subheader',{staticClass:"campus-search-bar__results__header"},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('views.schools.results.min-characters'),expression:"'views.schools.results.min-characters'"}]})]):_vm._e(),(_vm.suggestedCampuses.length > 0 || (_vm.searchTerm?.length > 2))?_c('v-subheader',{staticClass:"campus-search-bar__results__header"},[_c('span',[_vm._v(" "+_vm._s(_vm.resultHeaderText)+" ")])]):_vm._e(),_vm._l((_vm.suggestedPlaces),function(place){return _c('v-list-item',{key:place.p_location_id,attrs:{"title":`${place.city} ( ${place.upper_name} )`},on:{"click":function($event){return _vm.selectPlace(place)}}},[_c('v-list-item-avatar',{staticClass:"campus-search-bar__results__item--avatar",attrs:{"tile":"","color":'#1E0C61'}},[_c('v-img',{attrs:{"contain":"","src":require('@/assets/icons/campusDetail/places.svg')}})],1),_c('v-list-item-content',{staticStyle:{"text-align":"start"}},[_c('v-list-item-title',{staticClass:"campus-search-bar__results__item--title"},[_c('span',[_vm._v(" "+_vm._s(place.city)+" ")])]),_c('v-list-item-subtitle',{staticClass:"campus-search-bar__results__item--subtitle"},[_c('span',{directives:[{name:"t",rawName:"v-t",value:(place.upper_name),expression:"place.upper_name"}]})]),_c('v-list-item-subtitle',{staticClass:"campus-search-bar__results__item--details"},[_c('span',{domProps:{"innerHTML":_vm._s(place.label_subdivision)}})])],1)],1)}),_vm._l((_vm.suggestedCampuses),function(campus){return _c('v-list-item',{key:campus.uuid,attrs:{"title":`${campus.campus_short_name ?? campus.campus_name} ( ${campus.commune} )`},on:{"click":function($event){return _vm.selectCampus(campus)}}},[_c('v-list-item-avatar',{staticClass:"campus-search-bar__results__item--avatar",class:{
              'campus-search-bar__results__item--avatar--transparent': _vm.getCampusThumbnail(campus),
            },attrs:{"tile":"","color":_vm.getCampusThumbnail(campus) ? 'transparent' : 'grey lighten-2'}},[(_vm.getCampusThumbnail(campus))?_c('ImageAtom',{attrs:{"src":_vm.getCampusThumbnail(campus),"is-responsive":""}}):_c('ImageAtom',{staticClass:"campus-search-bar__results__item--avatar--default-icon",attrs:{"src":require('@/assets/icons/campusDetail/infrastructure-icon.svg')}})],1),_c('v-list-item-content',{staticStyle:{"text-align":"start"}},[_c('v-list-item-title',{staticClass:"campus-search-bar__results__item--title"},[_c('TitleVerifiedAccount',{attrs:{"title":_vm.getCampusName(campus),"prime":campus.campus_tether_pack,"verified":campus.verified}})],1),_c('v-list-item-subtitle',{staticClass:"campus-search-bar__results__item--subtitle"},[_c('span',{directives:[{name:"t",rawName:"v-t",value:(_vm.getInstitutionInfo(campus)),expression:"getInstitutionInfo(campus)"}]})]),_c('v-list-item-subtitle',{staticClass:"campus-search-bar__results__item--details"},[_c('span',{domProps:{"innerHTML":_vm._s(campus.commune)}})])],1)],1)})],2):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }