<template>
  <div class="register-main--safe-padding welcome-explorer__register--terms" style="position: relative">
    <!-- refactor terms and conditions, terms active 0 = terms tether,  -->
    <div class="fill-width">
      <ModalHeader
        style="margin-bottom: 24px"
        :content-sections="['close', 'title']"
        :title-text="'FAQ.title'"
        left-align-title
        @close="closeMethod()"
      />
      <ModalContent
        :content-sections="['MultiStyleText']"
        :multi-text-array="setTextArray1Section"
      />
      <ModalContent
        :content-sections="['MultiStyleText']"
        :multi-text-array="setTextArray2Section"
      />
      <ModalContent
        :content-sections="['MultiStyleText']"
        :multi-text-array="setTextArray3Section"
      />
      <ModalContent
        :content-sections="['MultiStyleText']"
        :multi-text-array="setTextArray4Section"
      />
      <ModalContent
        :content-sections="['MultiStyleText']"
        :multi-text-array="setTextArray5Section"
      />
      <ModalContent
        class="welcome-explorer__register--left"
        :content-sections="['MultiStyleText']"
        :multi-text-array="setTextArray6Section"
      />
      <ModalContent
        class="welcome-explorer__register--left"
        :content-sections="['MultiStyleText']"
        :multi-text-array="setTextArray7Section"
      />
    </div>
    <CallToActions
      class="programs-flow-modal--continue"
      :main-button-text="'campus-details.pride_point.contact'"
      :types="['main']"
      legacy-primary
      active-value
      small
      :spaced="false"
      @continue="showExplorerMeetingModal = true;"
    />
    <div
      :class="mobile ? 'terms-logos--menu--mobile' : 'terms-logos--menu'"
    >
      <ImageAtom
        v-for="({ src, alt, objectFit }, index) in logosTC"
        :key="index"
        class="terms-logos--menu__logo"
        :src="require(`@/assets/logos/${src}`)"
        :alt="alt"
        :object-fit="objectFit"
      />
    </div>
    <v-dialog
      v-model="showExplorerMeetingModal"
      :content-class="mobile ? 'user-dialog-mobile' : 'user-dialog-map'"
      transition="dialog-bottom-transition"
      :max-width="'480'"
    >
      <DashboardCalendar
        out-of-register
        style="padding: 32px 28px 32px 28px;"
        :calendar-url="explorerMeetingUrl"
        @closeModal="showExplorerMeetingModal = false;"
      />
    </v-dialog>
  </div>
</template>

<script>
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import DashboardCalendar from '@/components/organisms/register/CalendarModal.vue';
import ImageAtom from '@/components/atoms/images/ImageAtom.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import CONFIG from '@/config';

export default {
  name: 'FAQ',
  components: {
    ModalHeader,
    ModalContent,
    ImageAtom,
    CallToActions,
    DashboardCalendar,
  },
  props: {
    menu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showExplorerMeetingModal: false,
      localeKeyRoot: 'faq-us',
      explorerMeetingUrl: `${CONFIG.ambassadorCalendly}?month=2022-07?hide_landing_page_details=1&hide_gdpr_banner=1&text_color=1a0c4c&primary_color=1a0c4c" style="min-width:360px;height:630px;`,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    logosTC() {
      if (this.menu) {
        return [
          {
            src: 'inline-explorer-quality.svg',
            alt: 'explorer',
            objectFit: 'contain',
          },
          {
            src: 'power-by-tether.svg',
            alt: 'tether',
            objectFit: 'contain',
          },
        ];
      }
      return ['inline-tether-logo.svg'];
    },
    setTextArray1Section() {
      // ARRAY objectKey for text set styles with index
      const textSection = [
        {
          text: `FAQ.${this.localeKeyRoot}.first_question.question`,
          args: { country: CONFIG.tenant === 'newhaven' ? 'New Haven' : CONFIG.tenant },
          text2: `FAQ.${this.localeKeyRoot}.first_question.answer`,
          args2: { country: CONFIG.tenant === 'newhaven' ? 'New Haven' : CONFIG.tenant },
          tag: 'click_headmaster_reg_privacy-checkbox',
          bold: [1],
          spaced: [2],
        },
      ];
      return textSection;
    },
    setTextArray2Section() {
      // ARRAY objectKey for text set styles with index
      const textSection = [
        {
          text: `FAQ.${this.localeKeyRoot}.second_question.question`,
          text2: `FAQ.${this.localeKeyRoot}.second_question.answer`,
          tag: 'click_headmaster_reg_privacy-checkbox',
          bold: [1],
          spaced: [2],
        },
      ];
      return textSection;
    },
    setTextArray3Section() {
      // ARRAY objectKey for text set styles with index
      const textSection = [
        {
          text: `FAQ.${this.localeKeyRoot}.third_question.question`,
          text2: `FAQ.${this.localeKeyRoot}.third_question.answer`,
          tag: 'click_headmaster_reg_privacy-checkbox',
          bold: [1],
          spaced: [2],
        },
      ];
      return textSection;
    },
    setTextArray4Section() {
      // ARRAY objectKey for text set styles with index
      const textSection = [
        {
          text: `FAQ.${this.localeKeyRoot}.fourth_question.question`,
          text2: `FAQ.${this.localeKeyRoot}.fourth_question.answer`,
          text3: `FAQ.${this.localeKeyRoot}.fourth_question.answer2`,
          tag: 'click_headmaster_reg_privacy-checkbox',
          bold: [1],
          spaced: [2, 3],
        },
      ];
      return textSection;
    },
    setTextArray5Section() {
      // ARRAY objectKey for text set styles with index
      const textSection = [
        {
          text: `FAQ.${this.localeKeyRoot}.fifth_question.question`,
          text2: `FAQ.${this.localeKeyRoot}.fifth_question.answer`,
          tag: 'click_headmaster_reg_privacy-checkbox',
          bold: [1],
          spaced: [2],
        },
      ];
      return textSection;
    },
    setTextArray6Section() {
      // ARRAY objectKey for text set styles with index
      const textSection = [
        {
          text: `FAQ.${this.localeKeyRoot}.sixth_question.question`,
          text2: `FAQ.${this.localeKeyRoot}.sixth_question.answer`,
          text3: `FAQ.${this.localeKeyRoot}.sixth_question.answer2`,
          text4: `FAQ.${this.localeKeyRoot}.sixth_question.answer3`,
          text5: `FAQ.${this.localeKeyRoot}.sixth_question.answer4`,
          tag: 'click_headmaster_reg_privacy-checkbox',
          bold: [1],
          spaced: [2, 3, 4, 5],
        },
      ];
      return textSection;
    },
    setTextArray7Section() {
      // ARRAY objectKey for text set styles with index
      const textSection = [
        {
          text: `FAQ.${this.localeKeyRoot}.seventh_question.question`,
          text2: `FAQ.${this.localeKeyRoot}.seventh_question.answer`,
          tag: 'click_headmaster_reg_privacy-checkbox',
          bold: [1],
          spaced: [2],
        },
      ];
      return textSection;
    },
  },
  methods: {
    closeMethod() {
      this.$emit('closeModal');
    },
  },
};
</script>
<style scoped lang="scss">
.terms-logos {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem 1rem;
  align-items: center;
  margin-top: 3rem;

  &--menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem 1rem;
    margin-top: 3rem;
    align-items: center;
    padding-bottom: 30px !important;

    &--mobile{
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 3rem;
      padding-bottom: 30px !important;
    }

    &__logo{
      max-width: 20% !important;
    }
  }
}
</style>
