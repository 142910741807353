var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header",class:{
    'header--select': _vm.canSelectStudent,
    'header--mobile': _vm.mobile,
    'header--slim': _vm.currentRoute === 'Explorer',
  }},[_c('div',{staticClass:"header__row",class:{
      'header__row--borderless': _vm.borderless,
      'header__row--dashboard': _vm.currentRoute === 'Dashboard',
    }},[_c('div',{staticClass:"header__row__div"},[_c('NavigationButtons',{attrs:{"mobile":_vm.mobile,"in-dashboard":_vm.inDashboard,"show-first-modal":_vm.showFirstModal},on:{"closeMenu":_vm.closeMenu,"login":_vm.signIn,"signUp":_vm.signUp}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }