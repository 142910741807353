<template>
  <div
    class="header"
    :class="{
      'header--select': canSelectStudent,
      'header--mobile': mobile,
      'header--slim': currentRoute === 'Explorer',
    }"
  >
    <div
      class="header__row"
      :class="{
        'header__row--borderless': borderless,
        'header__row--dashboard': currentRoute === 'Dashboard',
      }"
    >
      <div class="header__row__div">
        <NavigationButtons
          :mobile="mobile"
          :in-dashboard="inDashboard"
          :show-first-modal="showFirstModal"
          @closeMenu="closeMenu"
          @login="signIn"
          @signUp="signUp"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import NavigationButtons from '@/components/explorer/NavigationButtons.vue';
import trackMixPanel from '../utils/mixpanel';

export default {
  name: 'CustomMenu',
  components: {
    NavigationButtons,
  },
  props: {
    inDashboard: {
      type: Boolean,
    },
    showFirstModal: {
      type: Boolean,
      default: false,
    },
    showRegisterModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: false,
      showLoginDialog: false,
      showUserRegister: false,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    canSelectStudent() {
      return [
        'Explorer',
        'Schools',
        'Simulate',
        'Favorites',
        'Register Student',
      ].includes(this.$route.name);
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    borderless() {
      return (
        ['Login', 'LoginApp', 'User Register'].includes(this.$route.name)
        || this.mobile
      );
    },
  },
  watch: {
    showRegisterModal: {
      handler(newVal) {
        if (newVal === true) {
          this.showUserRegister = true;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      setWantCreateAccount: 'authentication/setWantCreateAccount',
    }),
    signIn() {
      this.setWantCreateAccount({ wantCreateAccount: true });
      this.showMenu = false;
      this.showLoginDialog = false;
      this.$router.push({ name: 'Login' });
      this.closeMenu();
      trackMixPanel('menu_login');
    },
    signUp() {
      this.$router.push('/login');
    },
    closeMenu() {
      return this.$emit('closeMenu');
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
